import {
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Directive({
    selector: '[appScrollReachBottom]',
})
export class ScrollReachBottomDirective implements OnInit, OnDestroy {
    @Output()
    public onScrollReachBottom = new EventEmitter<boolean>();

    intervalSubscription$: Subscription;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        this.el.nativeElement.scrollTop = 0;

        this.el.nativeElement.addEventListener('scroll', event => {
            this.onScrollReachBottom.emit(this.isScrollReachBottom());
        });

        this.intervalSubscription$ = interval(1000).subscribe(() => {
            this.onScrollReachBottom.emit(this.isScrollReachBottom());
        });
    }

    private isScrollReachBottom(): boolean {
        const { scrollHeight, scrollTop, clientHeight } = this.el.nativeElement;

        if (this.el.nativeElement.scrollHeight <= this.el.nativeElement.clientHeight) {
            return true;
        }

        return Math.abs(scrollHeight - clientHeight - scrollTop) < 10;
    }

    ngOnDestroy(): void {
        this.intervalSubscription$?.unsubscribe();
    }
}
