<p-dialog
    *ngIf="notice"
    [header]="notice?.title"
    [visible]="true"
    [modal]="true"
    [closable]="false">
    <div class="modal-body">
        <div
            class="content"
            [innerHTML]="notice?.content | safeHtml"
            [appScrollReachBottom]
            (onScrollReachBottom)="onScrollReachBottomHandler($event)"></div>
    </div>
    <div class="modal-actions">
        <button
            pButton
            pRipple
            type="button"
            [label]="counter !== 0 ? counter : ('DEFAULT_TEXT.ConfirmText' | translate)"
            [disabled]="counter !== 0 || !scrollReachBottom"
            class="b-green"
            (click)="close()"></button>
    </div>
</p-dialog>
