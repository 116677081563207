import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationActionService } from '@app/modules/notification/services/notification-action.service';
import { NoticeFormModel, NoticeModalModel } from '@app/shared/models/notice.model';
import { NoticeService } from '@app/shared/services/api/notice.service';
import { takeWhile, tap, timer } from 'rxjs';

@Component({
    selector: 'app-notice-modal',
    templateUrl: './notice-modal.component.html',
    styleUrls: ['./notice-modal.component.scss'],
})
export class NoticeModalComponent implements OnInit {
    noticeId: number;
    notice: NoticeFormModel;

    scrollReachBottom = false;
    counter = 10;

    constructor(
        public noticeService: NoticeService,
        public notificationActionService: NotificationActionService,
        public dialogRef: MatDialogRef<NoticeModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: NoticeModalModel
    ) {
        this.noticeId = data.id;
    }

    async ngOnInit(): Promise<void> {
        this.noticeService.getById(this.noticeId).subscribe(res => {
            this.notice = res;

            this.setUpTimer();
        });
    }

    close(): void {
        this.noticeService.log(this.noticeId).subscribe(() => {
            this.dialogRef.close();
            this.notificationActionService.getNewNotificationList();
        });
    }

    onScrollReachBottomHandler(scrollReachBottom: boolean): void {
        this.scrollReachBottom = scrollReachBottom;
    }

    private setUpTimer(): void {
        timer(1000, 1000)
            .pipe(
                takeWhile(() => this.counter > 0),
                tap(() => this.counter--)
            )
            .subscribe();
    }
}
